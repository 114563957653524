










































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import moment, { Moment } from "moment";
import axios from "@/libs/axios/axios";
import { AxiosError, AxiosResponse } from "axios";
import { filter, findIndex, forEach, mapKeys } from "lodash";
import Calendar from "@/common/components/Calendar/Calendar.vue";
import { State } from "vuex-class";

import { UserState } from "@/common/store/user/types";

import { ReplacementInfoInterface } from "@/modules/director/interfaces/ReplacementInfoInterface";
import { PeriodInterface } from "@/modules/director/interfaces/ReplacementInfoInterface";

interface DayInterface {
  day: Moment;
  dayParts: {
    morning: boolean;
    afternoon: boolean;
  };
}

@Component({
  components: {
    Calendar
  }
})
export default class Absence extends Vue {
  @Prop() id!: string;
  @Prop() date!: string;

  calendarDialogVisible = false;
  absenceMonth = moment();

  absence: ReplacementInfoInterface = {
    replacer: "",
    substituting: "",
    school: "",
    address: "",
    place: "",
    startDate: moment(),
    endDate: moment(),
    periods: [],
    class: "",
    type: "",
    icon: "",
    morningDaypart: true,
    afternoonDaypart: true,
    id: 0
  };
  loaded = false;
  commentDialogVisible = false;

  @State("user") user!: UserState;

  async mounted() {
    if (this.user.currentTenant && this.user.currentTenant.id) {
      const response: void | AxiosResponse = await axios
        .get("absences/" + this.id, {
          headers: {
            "X-VVTOOL-TENANT": this.user.currentTenant.id
          }
        })
        .catch((error: AxiosError) => {
          console.log(error);
        });

      if (response && response.data) {
        this.absence = response.data;
        this.absenceMonth = moment(this.startDate);

        this.loaded = true;
      }
    }
  }

  changeDate(day: Moment) {
    if (!moment(this.date).isSame(day)) {
      this.$router.push({
        name: "Absence",
        params: { id: this.id, date: day.format("YYYY-MM-DD") }
      });
    }
  }

  get activeDate(): Moment {
    if (this.date) {
      return moment(this.date);
    } else {
      return this.startDate;
    }
  }

  get startDate(): Moment {
    if (this.absence.periods.length > 0) {
      return this.absence.periods[0].date;
    }
    return moment();
  }

  get endDate(): Moment {
    if (this.absence.periods.length > 0) {
      return this.absence.periods[this.absence.periods.length - 1].date;
    }
    return moment();
  }

  get currentDay(): {
    morning: PeriodInterface | null;
    afternoon: PeriodInterface | null;
  } | null {
    const currentPeriods = filter(this.absence.periods, ({ date }) =>
      moment(this.activeDate).isSame(date, "day")
    );

    if (currentPeriods.length > 0) {
      return {
        morning: null,
        afternoon: null,
        ...mapKeys(currentPeriods, ({ periodOfDay }) => {
          return periodOfDay == 1 ? "morning" : "afternoon";
        })
      };
    } else {
      return null;
    }
  }

  get daysWithEvents() {
    if (this.absence.periods.length > 0) {
      const confirmedDays: DayInterface[] = [];
      const pendingDays: DayInterface[] = [];
      const rejectedDays: DayInterface[] = [];

      forEach(this.absence.periods, function(period: PeriodInterface) {
        if (
          !period.filled &&
          !period.confirmed &&
          (period.pending || period.pending === null)
        ) {
          const foundIndex = findIndex(pendingDays, function(
            pendingDay: DayInterface
          ) {
            return pendingDay.day.isSame(moment(period.date), "day");
          });

          if (foundIndex >= 0) {
            if (period.periodOfDay === 1) {
              pendingDays[foundIndex].dayParts.morning = true;
            } else if (period.periodOfDay === 2) {
              pendingDays[foundIndex].dayParts.afternoon = true;
            }
          } else {
            pendingDays.push({
              day: moment(period.date),
              dayParts: {
                morning: period.periodOfDay === 1,
                afternoon: period.periodOfDay === 2
              }
            });
          }
        } else {
          if (period.confirmed) {
            confirmedDays.push({
              day: moment(period.date),
              dayParts: {
                morning: period.periodOfDay === 1,
                afternoon: period.periodOfDay === 2
              }
            });
          } else {
            rejectedDays.push({
              day: moment(period.date),
              dayParts: {
                morning: period.periodOfDay === 1,
                afternoon: period.periodOfDay === 2
              }
            });
          }
        }
      });

      return [
        {
          eventType: "single-event",
          color: "green",
          days: confirmedDays
        },
        {
          eventType: "single-event",
          color: "orange",
          days: pendingDays
        },
        {
          eventType: "single-event",
          color: "red",
          days: rejectedDays
        }
      ];
    } else {
      return [];
    }
  }

  absencePreviousMonth() {
    this.absenceMonth = this.absenceMonth.clone().subtract(1, "M");
  }

  absenceNextMonth() {
    this.absenceMonth = this.absenceMonth.clone().add(1, "M");
  }

  handleCommentClose() {
    this.commentDialogVisible = false;
  }

  getMonth(date: Moment) {
    return moment(date).startOf("month");
  }

  formatDate(date: Moment, format: string) {
    return moment(date).format(format);
  }
}
