var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('div',{attrs:{"id":"replacement-info"}},[_c('div',{staticClass:"infoBox"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"container"},[_c('router-link',{staticClass:"back-icon",attrs:{"to":{ name: 'Dashboard' },"tag":"div"}},[_c('i',{staticClass:"fa fa-times"})]),_c('h4',[_c('strong',[_vm._v(_vm._s(_vm.absence.substituting))])]),_c('h6',{staticClass:"capitalize"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.activeDate, "dddd D MMMM"))+" ")]),_c('ul',{staticClass:"row mt-5"},[_c('li',{staticClass:"col-4 col-md-2"},[_vm._v("Type:")]),_c('li',{staticClass:"col-8 col-md-10"},[_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(_vm.absence.kind))])]),_c('li',{staticClass:"col-4 col-md-2"},[_vm._v("School:")]),_c('li',{staticClass:"col-8 col-md-10"},[_vm._v(_vm._s(_vm.absence.school.name))]),_c('li',{staticClass:"col-4 col-md-2"},[_vm._v("Adres:")]),_c('li',{staticClass:"col-8 col-md-10"},[(_vm.absence.school.address)?_c('a',{attrs:{"target":"blank","href":'https://www.google.com/maps/search/?api=1&query=' +
                  _vm.absence.school.address.replaceAll(' ', '+')}},[_vm._v(_vm._s(_vm.absence.school.address))]):_vm._e()]),_c('li',{staticClass:"col-4 col-md-2"},[_vm._v("Klas:")]),_c('li',{staticClass:"col-8 col-md-10"},[_vm._v(" "+_vm._s(_vm.absence.classes ? _vm.absence.classes.join(", ") : "")+" ")]),_c('li',{staticClass:"col-4 col-md-2"},[_vm._v("Datum:")]),_c('li',{staticClass:"col-8 col-md-10"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.startDate, "DD-MM-YY"))+" t/m "+_vm._s(_vm.formatDate(_vm.endDate, "DD-MM-YY"))+" ")]),_c('br'),_c('br'),(_vm.currentDay)?_c('li',{staticClass:"col-4 col-md-2"},[_vm._v("Ochtend:")]):_vm._e(),(_vm.currentDay)?_c('li',{staticClass:"col-8 col-md-10"},[(_vm.currentDay.morning !== null)?_c('span',[(_vm.currentDay.morning.substitute)?[_vm._v(" "+_vm._s(_vm.currentDay.morning.substitute.name)+" ("),(_vm.currentDay.morning.pending)?[_vm._v("In aanvraag")]:(
                    !_vm.currentDay.morning.pending &&
                      _vm.currentDay.morning.confirmed
                  )?[_vm._v("Geaccepteerd")]:(!_vm.currentDay.morning.confirmed)?[_vm._v("Geweigerd")]:_vm._e(),_vm._v(") ")]:[_vm._v(" Openstaand ")]],2):_c('span',[_vm._v("Niet afwezig")])]):_vm._e(),(_vm.currentDay)?_c('li',{staticClass:"col-4 col-md-2"},[_vm._v("Middag:")]):_vm._e(),(_vm.currentDay)?_c('li',{staticClass:"col-8 col-md-10"},[(_vm.currentDay.afternoon !== null)?_c('span',[(_vm.currentDay.afternoon.substitute)?[_vm._v(" "+_vm._s(_vm.currentDay.afternoon.substitute.name)+" ("),(_vm.currentDay.afternoon.pending)?[_vm._v("In aanvraag")]:(
                    !_vm.currentDay.afternoon.pending &&
                      _vm.currentDay.afternoon.confirmed
                  )?[_vm._v("Geaccepteerd")]:(!_vm.currentDay.afternoon.confirmed)?[_vm._v("Geweigerd")]:_vm._e(),_vm._v(") ")]:[_vm._v(" Openstaand ")]],2):_c('span',[_vm._v("Niet afwezig")])]):_vm._e(),(!_vm.currentDay)?_c('li',{staticClass:"col-12"},[_vm._v(" Er is voor deze dag geen vervanging aangevraagd. ")]):_vm._e()]),(_vm.absence.note)?_c('a',{on:{"click":function($event){$event.preventDefault();_vm.commentDialogVisible = true}}},[_vm._v("Opmerking coördinator")]):_vm._e()],1)]),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-right"},[_c('div',{staticClass:"controls d-flex justify-content-end align-items-center"},[_c('a',{on:{"click":_vm.absencePreviousMonth}},[_c('i',{staticClass:"far fa-angle-left"})]),_c('a',{on:{"click":_vm.absenceNextMonth}},[_c('i',{staticClass:"far fa-angle-right"})])])])]),_c('Calendar',{attrs:{"month":_vm.getMonth(_vm.absenceMonth),"daysWithEvents":_vm.daysWithEvents,"hideToday":true},on:{"selectDate":_vm.changeDate}})],1)])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"row justify-content-between align-items-center"},[_c('div',{staticClass:"col-auto"},[_c('router-link',{staticClass:"back",attrs:{"to":{ name: 'Dashboard' },"tag":"a"}},[_c('i',{staticClass:"fas fa-arrow-alt-left"}),_vm._v(" Naar overzicht ")])],1)])]),_c('el-dialog',{staticClass:"comment-coordinator full-screen-dialog",attrs:{"visible":_vm.commentDialogVisible,"width":"100%","before-close":_vm.handleCommentClose,"append-to-body":true},on:{"update:visible":function($event){_vm.commentDialogVisible=$event}}},[_c('div',{staticClass:"container"},[_c('p',[_vm._v(" "+_vm._s(_vm.absence.note)+" ")])])])],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"row info-color"},[_c('li',{staticClass:"col-4"},[_c('span',{staticClass:"is-orange"},[_vm._v("In aanvraag")])]),_c('li',{staticClass:"col-4"},[_c('span',{staticClass:"is-red"},[_vm._v("Geweigerd")])]),_c('li',{staticClass:"col-4"},[_c('span',{staticClass:"is-green"},[_vm._v("Ingepland")])])])}]

export { render, staticRenderFns }